/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <body>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        menu={[
          { name: "Ministries", link: "/ministries" },
          { name: "Resources", link: "/resources" },
          { name: "Staff", link: "/staff" },
          { name: "What we believe", link: "/believe" },
          { name: "Contact", link: "/contact" },
        ]}
      />
      <main>{children}</main>
      <Footer />
    </body>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
