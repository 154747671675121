import * as React from "react"

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    stroke="white"
  >
    <path d="M990 57.1 942.9 10 500 452.9 57.1 10 10 57.1 452.9 500 10 942.9 57.1 990 500 547.1 942.9 990l47.1-47.1L547.1 500 990 57.1z" />
  </svg>
)

export default SvgComponent
