import React, { useState } from "react"
import { Link } from "gatsby"

const MobileMenu = ({ menu }) => {
  return (
    <div class="w-full md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        {menu.map((item, index) => {
          return (
            <Link
              key={index}
              to={item.link}
              class="block px-3 py-2 rounded-md text-base font-medium text-white bg-blue-900 focus:outline-none focus:text-white focus:bg-gray-700"
            >
              {item.name}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
export default MobileMenu
