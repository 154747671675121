import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import HamburgerIcon from "./HamburgerIcon"
import CrossIcon from "./CrossIcon"

import MobileMenu from "./MobileMenu"
const Menu = ({ menu }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div class="flex items-center justify-between  ">
        <div class="flex-shrink-0 items-center">
          <Link to="/">
            <StaticImage
              src="../images/logo.png"
              alt="New Life Christian church"
              width={"160px"}
            />
          </Link>
        </div>

        <div class="flex items-center">
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              {menu.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.link}
                    className="mx-4 text-lg border-b-2 border-transparent hover:border-b-2 hover:border-indigo-300 transition duration-500"
                  >
                    {item.name}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div class="-mr-2 flex md:hidden">
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            onClick={current => {
              setIsOpen(!isOpen)
            }}
          >
            {isOpen ? <CrossIcon /> : <HamburgerIcon />}
          </button>
        </div>
      </div>
      <div style={{ display: isOpen ? "block" : "none" }}>
        <MobileMenu menu={menu} />
      </div>
    </>
  )
}

export default Menu
