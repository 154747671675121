import * as React from "react"
import Layout from "../components/layout"
const IndexPage = () => (
  <Layout>
    <div class="overflow-x-hidden bg-gradient-to-b from-blue-800 ">
      <div class="px-6 py-8">
        <div class="max-w-7xl  container flex justify-between mx-auto radius-md">
          <div class="container overflow-x-hidden  ">
            <div class="overflow-x-hidden bg-gradient-to-b from-blue-800 ">
              <h1 class="text-xl text-white text-center  p-4 justify-center text-5xl	">
                Contact us
              </h1>
              <div class="max-w-5xl mx-auto px-6 sm:px-6 lg:px-8 mb-12">
                <div class="bg-white w-full shadow rounded p-8 sm:p-12 mt-10">
                  <form action="" method="post">
                    <div class="md:flex items-center mt-12">
                      <div class="w-full md:w-1/2 flex flex-col">
                        <label class="font-semibold leading-none">Name</label>
                        <input
                          type="text"
                          class="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"
                        />
                      </div>
                      <div class="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                        <label class="font-semibold leading-none">Phone</label>
                        <input
                          type="email"
                          class="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"
                        />
                      </div>
                    </div>
                    <div class="md:flex items-center mt-8">
                      <div class="w-full flex flex-col">
                        <label class="font-semibold leading-none">
                          Subject
                        </label>
                        <input
                          type="text"
                          class="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="w-full flex flex-col mt-8">
                        <label class="font-semibold leading-none">
                          Message
                        </label>
                        <textarea
                          type="text"
                          class="h-40 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"
                        ></textarea>
                      </div>
                    </div>
                    <div class="flex items-center justify-center w-full">
                      <button class="mt-9 font-semibold leading-none text-white py-4 px-10 bg-red-800 rounded hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 focus:outline-none">
                        Send message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
