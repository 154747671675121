import React from "react"
import PropTypes from "prop-types"
import Menu from "./Menu"

const Header = props => {
  return (
    <div class="px-6 py-2 ">
      <Menu {...props} />
    </div>
  )
}

export default Header
